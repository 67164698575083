'use client';

import { sendGTMEvent } from '@next/third-parties/google';
import { ItemOfferType } from 'types/cms/offer-of-week';
import { ProductData, ProductItemType } from 'types/cms/product-carousel';
import { CartType } from 'types/my-cart';
import { OrderDetailsType } from 'types/order';
import { AlgoliaProduct } from 'types/product';
import { IAddressData } from 'types/shipping-method-switcher';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getProductsForAnalytics } from 'utils/analytics';

export function gtmSetSelectItem(
  product: AlgoliaProduct | ProductItemType,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  let currency = '';
  let category_id = '';
  let sku_id = '';
  let price = '';
  if ('price' in product) {
    currency = Object.keys(product?.price)?.[0]?.toString() || '';
  } else {
    currency = product?.attributes?.currency;
  }
  if ('categoryIds' in product) {
    category_id =
      product?.categoryIds?.[product?.categoryIds?.length - 1] || '';
  } else {
    category_id =
      product.attributes?.categories?.[
        product.attributes?.categories?.length - 1
      ]?.category_id || '';
  }
  if ('sku' in product) {
    sku_id = product?.sku || '';
  } else {
    sku_id = product?.sku_id || '';
  }
  if ('price' in product) {
    price = product?.price?.[0]?.default?.toString() || '';
  } else {
    price = product?.attributes?.price?.toString() || '';
  }

  getProductsForAnalytics(sku_id, category_id, language, region).then(
    (response) =>
      response.json().then((res) => {
        sendGTMEvent({
          event: 'select_item',
          ecommerce: {
            currency: currency,
            value: price,
            items: res,
          },
        });
      })
  );
}

export function gtmSetSignUp(
  status: string,
  email: string,
  phone: any,
  userId: any,
  firstName: string,
  lastName: string
) {
  const currentDate = new Date();
  const options = {
    weekday: 'short' as const,
    year: 'numeric' as const,
    month: 'short' as const,
    day: 'numeric' as const,
    hour: '2-digit' as const,
    minute: '2-digit' as const,
    second: '2-digit' as const,
    timeZoneName: 'short' as const,
  };

  const formattedDate = currentDate
    .toLocaleString('en-US', options)
    .replace(',', '');
  sendGTMEvent({
    event: 'sign_up',
    method: 'Mobile',
    status: status,
    user_id: userId || '',
    email: email,
    phone: phone.trim(),
    first_name: firstName,
    last_name: lastName,
    referral_url: 'https://www.nahdionline.com',
    event_source: 'Website',
    account_creation_date: formattedDate,
  });
}

export function gtmSetButtonClick(name: string, role: string) {
  sendGTMEvent({
    event: 'button_click',
    button_name: name,
    button_destination: role, // TODO: For this field to work correctly, a role must be given to the component.
  });
}

export function gtmSetOrderExperience(rate: number, number: string) {
  sendGTMEvent({
    event: 'order_experience_rating',
    rate_score: rate,
    service_type: 'Click & Collect',
    transaction_id: number,
  });
}

export function gtmSetPurchase(
  data: OrderDetailsType,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const skuIds = data?.items?.map((item) => item.product_sku).join(',');
  fetch(
    `/api/analytics/product?skus=${skuIds}&language=${language}&region=${region}`,
    {
      method: 'GET',
    }
  ).then((response) => {
    response.json().then((res) => {
      sendGTMEvent({
        event: 'purchase',
        ecommerce: {
          value: data?.total?.base_grand_total?.value,
          currency: data?.total?.base_grand_total?.currency,
          transaction_id: data?.number,
          tax: data?.total_tax_amount?.value,
          shipping: data?.payment?.shipping_amount,
          payment_type:
            data?.payment?.method === 'Qitaf Points'
              ? 'Qitaf Points'
              : 'Credit Card',
          items_array: res,
          items: res,
        },
      });
    });
  });
}

export function gtmSetAddShippingInfo(
  cart: CartType,
  deliveryMode: string,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const skuIds = cart?.lineItems?.map((item) => item.skuId).join(',');
  fetch(
    `/api/analytics/product?skus=${skuIds}&language=${language}&region=${region}`,
    {
      method: 'GET',
    }
  ).then((response) => {
    response.json().then((res) => {
      sendGTMEvent({
        event: 'add_shipping_info',
        ecommerce: {
          value: cart?.sub_total_with_tax,
          currency: cart?.currency_code,
          coupon: cart?.applied_coupons
            ?.map((coupon) => coupon.value)
            .join(','),
          shipping_tier:
            deliveryMode === 'delivery' ? 'Home Delivery' : 'Store Pickup',
          items_array: res,
          items: res,
        },
      });
    });
  });
}

export function gtmSetSelectPromotion(
  categoryItem: ItemOfferType,
  location_id: string
) {
  sendGTMEvent({
    event: 'select_promotion',
    ecommerce: {
      items: [
        {
          creative_name: categoryItem?.mobile_image,
          creative_slot: categoryItem?.label,
          location_id: location_id,
          promotion_id: categoryItem?.label,
          promotion_name: categoryItem?.label,
          promotion_destination: categoryItem?.link || '',
        },
      ],
    },
  });
}

export function gtmSetViewPromotion(data: any, location_id: string) {
  sendGTMEvent({
    event: 'view_promotion',
    ecommerce: {
      items: data?.items?.map((categoryItem: any) => ({
        creative_name: categoryItem?.mobile_image || categoryItem?.mobileImage,
        creative_slot: categoryItem?.label || categoryItem?.link,
        location_id: location_id,
        promotion_id:
          categoryItem?.label || categoryItem?.buttonName || data.title,
        promotion_name:
          categoryItem?.label || categoryItem?.buttonName || data.title,
        promotion_destination: categoryItem?.link || '',
      })),
    },
  });
}

export function gtmSetWishList(
  productId: string,
  isRemove: boolean,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  getProductsForAnalytics(productId, undefined, language, region).then(
    (response) =>
      response.json().then((res) => {
        sendGTMEvent({
          event: isRemove ? 'remove_from_wishlist' : 'add_to_wishlist',
          ecommerce: {
            currency: res[0].currency,
            value: res[0].price,
            items: res,
          },
        });
      })
  );
}

export function gtmSetCategoryClick(item: any) {
  const parameters = item?.megamenu_item_list?.reduce(
    (
      acc: { [x: string]: any },
      item: { link: null; label: any },
      index: number
    ) => {
      acc[`category${index + 1}_id`] = item.link || null;
      acc[`category${index + 1}_name`] = item.label;
      return acc;
    },
    {}
  );

  sendGTMEvent({
    event: 'category_click',
    category_id: item?.link,
    category_name: item?.label,
    ...parameters,
  });
}

export async function gtmSetAddPaymentInfo(
  cart: CartType,
  activePaymentMethod: string,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  sendGTMEvent({
    ecommerce: null,
  });
  const skuIds = cart?.lineItems?.map((item) => item.skuId).join(',');
  await fetch(
    `/api/analytics/product?skus=${skuIds}&language=${language}&region=${region}`,
    {
      method: 'GET',
    }
  ).then((response) => {
    response.json().then((res) => {
      sendGTMEvent({
        event: 'add_payment_info',
        ecommerce: {
          value: cart?.sub_total_with_tax,
          currency: cart?.currency_code,
          payment_type:
            activePaymentMethod === 'Qitaf Points'
              ? 'Qitaf Points'
              : 'Credit Card',
          coupon: cart?.applied_coupons
            ?.map((coupon) => coupon.value)
            .join(','),
          items_array: res,
          items: res,
        },
      });
    });
  });
}

export async function gtmSetBeginCheckout(
  cart: CartType,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  sendGTMEvent({
    ecommerce: null,
  });
  const skuIds = cart?.lineItems?.map((item) => item.skuId).join(',');
  await fetch(
    `/api/analytics/product?skus=${skuIds}&language=${language}&region=${region}`,
    {
      method: 'GET',
    }
  ).then((response) => {
    response.json().then((res) => {
      sendGTMEvent({
        event: 'begin_checkout',
        ecommerce: {
          value: cart?.sub_total_with_tax,
          currency: cart?.currency_code,
          items_array: res,
          items: res,
        },
      });
    });
  });
}

export async function gtmSetBeginCheckoutOOSModal(
  products: any,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  sendGTMEvent({
    ecommerce: null,
  });
  const skuIds = products?.map((item: any) => item.sku_id).join(',');
  const prices = products?.map((item: any) => item.attributes.price_net);
  const total = prices.reduce(
    (totalPrice: any, price: any) => totalPrice + price,
    0
  );
  if (products.length > 0) {
    await fetch(
      `/api/analytics/product?skus=${skuIds}&language=${language}&region=${region}`,
      {
        method: 'GET',
      }
    ).then((response) => {
      response.json().then((res) => {
        sendGTMEvent({
          event: 'begin_checkout',
          ecommerce: {
            value: total,
            currency: products[0]?.attributes?.currency,
            items_array: res,
            items: res,
          },
        });
      });
    });
  }
}

export function gtmSetViewCart(
  cart: CartType,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const skuIds = cart?.lineItems?.map((item) => item.skuId).join(',');
  fetch(
    `/api/analytics/product?skus=${skuIds}&language=${language}&region=${region}`,
    {
      method: 'GET',
    }
  ).then((response) => {
    response.json().then((res) => {
      sendGTMEvent({
        event: 'view_cart',
        ecommerce: {
          value: cart?.sub_total_with_tax,
          currency: cart?.currency_code,
          items_array: res,
          items: res,
        },
      });
    });
  });
}

export function gtmSetCoupounRedemption(c: string, result: string) {
  sendGTMEvent({
    event: 'coupon_redemption',
    code: c,
    redemption_result: result,
  });
}

export function gtmSetViewItem(
  productData: ProductData,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const category_id =
    productData?.product_data?.attributes?.categories?.[
      productData?.product_data?.attributes?.categories?.length - 1
    ]?.category_id;

  getProductsForAnalytics(
    productData?.product_data?.sku_id,
    category_id?.toString(),
    language,
    region
  ).then((response) =>
    response.json().then((res) => {
      sendGTMEvent({
        event: 'view_item',
        ecommerce: {
          currency: productData?.product_data?.attributes?.currency,
          value: productData?.product_data?.attributes?.price,
          items: res,
        },
      });
    })
  );
}

export function gtmSetViewItemList(
  hits: any,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const skuIds = hits?.map((item: { sku: any }) => item.sku).join(',');
  fetch(
    `/api/analytics/product?skus=${skuIds}&language=${language}&region=${region}`,
    {
      method: 'GET',
    }
  ).then((response) => {
    response.json().then((res) => {
      sendGTMEvent({
        ecommerce: null,
      });
      sendGTMEvent({
        event: 'view_item_list',
        ecommerce: {
          currency: res[0]?.currency,
          items: res,
        },
      });
    });
  });
}

export function gtmSetSearch(
  searchParams: string,
  count: number,
  algolia: any
) {
  sendGTMEvent({
    event: 'search',
    ...algolia,
    search_term: searchParams,
    search_results_count: count,
  });
}

export function gtmSetFilterUsed(facetLabel: string, item: any) {
  sendGTMEvent({
    event: 'filters_used',
    filter_name: facetLabel,
    filter_value: item.value,
    filter_value_id: item.value,
  });
}

export function gtmSetLocationDetection(formattedAddress: any) {
  sendGTMEvent({
    event: 'location_detection',
    location_status: 'Location Detection Success',
    city: formattedAddress.city,
    district: formattedAddress.city,
  });
}

export function gtmSetAddShippingAddress(data: IAddressData, customer_id: any) {
  sendGTMEvent({
    event: 'add_shipping_address',
    user_id: customer_id || '',
    country: data.country_code,
    city: data.city,
    address: data.additional?.formatted_address,
  });
}

export function gtmSetLogin(
  status: string,
  userId: any,
  email: string,
  phoneNumber: any,
  firstName: string,
  lastName: string
) {
  sendGTMEvent({
    event: 'login',
    method: 'Mobile',
    status: status,
    user_id: userId || '',
    email: email,
    phone: phoneNumber,
    first_name: firstName,
    last_name: lastName,
    referral_url: 'https://www.nahdionline.com',
    event_source: 'Website',
  });
}

export function gtmSetAddToCart(
  product: any,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  getProductsForAnalytics(product?.sku, undefined, language, region).then(
    (responseProduct) =>
      responseProduct.json().then((res) => {
        sendGTMEvent({
          event: 'add_to_cart',
          ecommerce: {
            currency: res[0].currency,
            value: res[0].price,
            items: res,
          },
        });
      })
  );
}

export function gtmSetRemoveFromCart(
  product: any,
  language: LanguageCodesType,
  region: RegionCodesType,
  basket?: any
) {
  if (!basket && product) {
    getProductsForAnalytics(
      product?.sku || '',
      undefined,
      language,
      region
    ).then((responseProduct) =>
      responseProduct.json().then((res) => {
        sendGTMEvent({
          event: 'remove_from_cart',
          ecommerce: {
            currency: res[0].currency,
            value: res[0].price,
            items: res,
          },
        });
      })
    );
  } else if (product.quantity < 1) {
    const deletedProduct = basket?.items?.find(
      (item: { product: { id: any } }) => item.product.id === product.id
    );
    getProductsForAnalytics(
      deletedProduct?.product?.sku || '',
      undefined,
      language,
      region
    ).then((responseProduct) =>
      responseProduct.json().then((res) => {
        sendGTMEvent({
          event: 'remove_from_cart',
          ecommerce: {
            currency: res[0].currency,
            value: res[0].price,
            items: res,
          },
        });
      })
    );
  }
}

export function gtmSetGlobalVariables(
  lng: string,
  country: string,
  miniProfile: any,
  pageType: string,
  shippingMethod: string
) {
  //TODO: we are waiting for clear where this event should be used
  sendGTMEvent({
    event: 'global_variables',
    store_language: lng,
    store: country,
    user_id: '1085813',
    userId: '1085813',
    page_type: pageType,
    event_source: 'Website',
    login_status: 'Logged In',
    shipping_method: shippingMethod,
    store_id: 'KSA-7209',
    gender: 'Male',
    nuhdeek_balance: '2500',
    city: 'Jeddah',
    phone: '966595753370',
    email: 'bassam@daam.com.sa',
    login_authenticity: 'self',
    responsys_campaign_id: 'nahdimedi.48524',
    dyid: '415231790',
    dyid_server: '415231790',
    dyjsession: 'dttlrpbnfpfi5y9i299q3d4l20yeqas3',
    algolia_userToken: 'anonymous-3776824d-3580-4c85-a3b4-2a0b925',
  });
}

export function gtmSetAvailability(availability: string) {
  sendGTMEvent({
    event: 'availability',
    availability_status: availability,
  });
}

export function gtmSetApiCall(map: any) {
  sendGTMEvent({
    event: 'api_call',
    service: 'Maps',
    endpoint_name: 'Google Maps',
    request_url: 'https://maps.googleapis.com/maps/api/js',
    request_method: 'GET',
    status_code: map ? '200' : '500',
  });
}

export function gtmSetPaymentFailed(method: string, reason: string) {
  sendGTMEvent({
    event: 'payment_failed',
    method: method,
    reason: reason,
  });
}

export function gtmSetErxOrderType(funnelName: string) {
  sendGTMEvent({
    event: 'order_type',
    event_cat: 'eRx Event',
    funnel_name: funnelName,
    step_no: 0,
  });
}

export function gtmSetErxInformation(
  funnelName: string,
  insurance_company: string
) {
  sendGTMEvent({
    event: 'information',
    event_cat: 'eRx Event',
    funnel_name: funnelName,
    insurance_company: insurance_company,
    step_no: 1,
  });
}

export function gtmSetErxShipping(
  funnelName: string,
  shipping_method: string,
  store: string,
  ez_pill: boolean,
  stepNo = 2
) {
  sendGTMEvent({
    event: 'shipping',
    event_cat: 'eRx Event',
    funnel_name: funnelName,
    shipping_method: shipping_method,
    store: store,
    ez_pill: ez_pill ? 'Yes' : 'No',
    step_no: stepNo,
  });
}

export function gtmSetErxConfirmation(
  funnelName: string,
  shipping_method: string,
  store: string,
  ez_pill: boolean,
  step = 3,
  attr = {}
) {
  sendGTMEvent({
    event: 'confirmation',
    event_cat: 'eRx Event',
    funnel_name: funnelName,
    shipping_method: shipping_method,
    store: store,
    ez_pill: ez_pill ? 'Yes' : 'No',
    step_no: step,
    ...attr,
  });
}

export function gtmSetErxOrderSuccess(
  funnelName: string,
  shipping_method: string,
  store: string,
  ez_pill: boolean,
  orderNo: string,
  step = 4,
  attr = {}
) {
  sendGTMEvent({
    event: 'order_success',
    event_cat: 'eRx Event',
    funnel_name: funnelName,
    shipping_method: shipping_method,
    order_no: orderNo,
    store: store,
    ez_pill: ez_pill ? 'Yes' : 'No',
    step_no: step,
    ...attr,
  });
}

export function gtmSetErxOrderExperienceRating(
  funnelName: string,
  orderNo: string,
  rate: number,
  attr = {}
) {
  sendGTMEvent({
    event: 'order_experience_rating',
    order_no: orderNo,
    service_type: 'E-Perscription',
    rate_score: rate,
    funnel_name: funnelName,
    erx_payment_type: 'Insurance',
    ...attr,
  });
}

export function gtmSetErxSelectPatient() {
  sendGTMEvent({
    event: 'select_patient',
    event_cat: 'eRx Event',
    funnel_name: 'Refill',
    step_no: 1,
  });
}

export function gtmSetErxSelectMedicines() {
  sendGTMEvent({
    event: 'select_medicines',
    event_cat: 'eRx Event',
    funnel_name: 'Refill',
    step_no: 2,
  });
}

export function gtmSetErxSelectPaymentType(paymentType: string) {
  sendGTMEvent({
    event: 'payment_type',
    event_cat: 'eRx Event',
    funnel_name: 'Refill',
    step_no: 3,
    erx_payment_type: paymentType,
  });
}
